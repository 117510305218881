import React from "react"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import Layout from "@app-components/layout/"
import Background from "@app-components/utils/background"
import Form from "@page-components/contact/form/"

const Contact = () => (
  <Layout getStartedButtons={["demo", "get-started"]} page="contact">
    <Background tagName="section" className="bg-cover bg-no-repeat bg-center" type="red-single">
      <div
        style={{
          paddingTop: "12rem",
          paddingBottom: "14rem"
        }}
        className="white center bg-cover px2 md-px3 pb4">
        <h1>We&apos;d love to chat</h1>
        <p className="large-p-tag max-width-2 mx-auto mt1">
          Expore our&nbsp;
          <OutboundLink href="https://support.cinesend.com" target="_blank" rel="noopener noreferrer">
            <span className="underline white">Help Docs</span>
          </OutboundLink>
          &nbsp;or contact our team
        </p>
      </div>
    </Background>
    <Background tagName="section" className="bg-cover bg-no-repeat bg-center" type="gray-right-rounded">
      <Form />
    </Background>
  </Layout>
)

export default Contact
